<template>
  <section id="final-users">
    <b-row class="mb-2">
      <b-col
        md="6"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("finalUsers.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("finalUsers.subtitle") }}
        </h5>
      </b-col>
    </b-row>
    <b-card>
      <table-header
        :per-page.sync="perPage"
        :search-query.sync="searchQuery"
        @export="exportTable"
      />
      <b-table
        ref="refUserListTable"
        :items="fetchedUsers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        hover
        :busy="isBusy"
        :select-mode="'single'"
        responsive="sm"
        selectable
        :sort-desc.sync="isSortDirDesc"
        show-empty
        :empty-text="$t('emptyText')"
        primary-key="id"
        @row-selected="onRowSelected"
      >
        <!-- Column: User -->
        <template #table-busy>
          <div
            v-if="!exporting"
            class="text-center text-danger my-2"
          >
            <b-spinner class="align-middle mr-1" />
            <strong>{{ $t('load') }}...</strong>
          </div>
          <div
            v-else
            class="text-center my-2"
          >
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="30"
              />
              <p id="cancel-label">
                {{ $t('exporting') }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.node.firstName)"
                variant="light-success"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.node.firstName }} {{ data.item.node.lastName }}
            </span>

            <span class="font-weight-bold d-block text-nowrap">
              <small class="text-muted">@{{ cleanUsername(data.item.node.username) }}</small>
            </span>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="MailIcon"
              size="18"
              class="mr-50"
            />
            <span class="align-text-top">{{ data.item.node.email }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.node.isActive)}`
            "
            class="text-capitalize"
          >
            <feather-icon
              :icon="data.item.node.isActive ? 'CheckIcon' : 'XIcon'"
              size="18"
              class="mr-50"
            />
            {{
              data.item.node.isActive
                ? $t("dataGeneric.active")
                : $t("dataGeneric.inactive")
            }}
          </b-badge>
        </template>
      </b-table>
      <table-footer
        v-model="currentPage"
        :from="dataMeta.from"
        :to="dataMeta.to"
        :total="totalUsers"
        :per-page="perPage"
      />
    </b-card>
  </section>
</template>
<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BMedia,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import axios from '@axios'
import { messageError, cleanUsername, showToast } from '@/store/functions'
import xlsExport from 'xlsexport'
import { getUserData } from '@/auth/utils'
import TableFooter from '../components/table/TableFooter.vue'
import TableHeader from '../components/table/TableHeader.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BMedia,
    BAvatar,
    BBadge,
    // My components
    TableFooter,
    TableHeader,
  },
  data() {
    return {
      // Table Handlers
      tableColumns: [
        { key: 'username', sortable: true, label: this.$t('firstName') },
        { key: 'email', sortable: true },
        { key: 'isActive', sortable: true, label: this.$t('editContent.status') },
      ],
      userData: getUserData(),
      selected: null,
      dataToExport: [],
      isBusy: false,
      exporting: false,
      perPage: 10,
      totalUsers: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      fetchedUsers: null,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refUserListTable ? this.$refs.refUserListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
  },
  watch: {
    searchQuery() {
      this.fetchUsers()
    },
    perPage() {
      this.fetchUsers()
    },
    currentPage() {
      this.fetchUsers()
    },
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    avatarText,
    cleanUsername,
    onRowSelected(items) {
      if (items[0]) {
        this.selected = items[0].node
        this.$emit('finalUser', this.selected)
      }
    },
    seleccionar() {
      this.$emit('finalUser', this.selected)
    },
    fetchUsers() {
      const data = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      this.fetchUsersQuery(data)
        .then(response => {
          const { edges, totalCount } = response.data.data.allVusers
          this.fetchedUsers = edges
          this.totalUsers = totalCount
        })
        .catch(() => {
          showToast(this.$t('errorList'), 2, this)
        })
    },
    fetchUsersQuery(queryParams) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            // params: queryParams
            query: `
              {
                allVusers(
                    first: ${queryParams.perPage},
                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                    username: "${queryParams.q}",
                    client:"${userData.profile.client.id}",

                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                  ) {
                  totalCount
                  edgeCount
                  edges {
                    node {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      isLegalWarning
                      isAcceptAds
                    }
                  }
                }
              }
            `,
          })
          .then(response => {
            const allVusers = response
            const searchName = response.data.data.allVusers.edges

            axios
              .post('', {
                // params: queryParams
                query: `
                              {
                                allVusers(
                                    first: ${queryParams.perPage},
                                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                                    email: "${queryParams.q}",
                   client:"${userData.profile.client.id}",

                                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                                  ) {
                                  totalCount
                                  edgeCount
                                  edges {
                                    node {
                                      id
                                      username
                                      firstName
                                      lastName
                                      email
                                      isActive
                                      isLegalWarning
                                      isAcceptAds
                                    }
                                  }
                                }
                              }
                          `,
              }).then(result => {
                const searchMail = result.data.data.allVusers.edges
                let nuevo = [...searchName, ...searchMail]

                const hash = {}
                nuevo = nuevo.filter(current => {
                  const exists = !hash[current.node.id]
                  hash[current.node.id] = true
                  return exists
                })
                allVusers.data.data.allVusers.edges = nuevo
                resolve(allVusers)
              }).catch(err => {
                reject(err)
              })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    resolveUserStatusVariant(status) {
      if (status === true) return 'success'
      if (status === false) return 'secondary'
      return 'secondary'
    },
    deleteUser(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteUserMutation(id)
            .then(response => {
              const { found, deletedId } = response.data.data.deleteVuser
              if (found && deletedId) {
                this.fetchUsers()
                showToast(this.$t('success'), 1, this)
              }
            })
            .catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      })
    },
    exportData(cursor = '', typeExport) {
      const query = `{
            allVusers (client:"${this.userData.profile.client.id}",after:"${cursor}"){
                pageInfo {
                endCursor
                hasNextPage
              }
              edges {
                node {
                  id
                  username
                email
                firstName
                lastName
                isLegalWarning
                isAcceptAds
                isActive
                dateJoined
                  
                }
              }
            }
          }`
      axios
        .post('', {
          query,
        })
        .then(response => {
          messageError(response, this)
          const info = response.data.data.allVusers
          const page = info.pageInfo
          const { edges } = info
          const nodes = edges.map(item => item.node)
          this.dataToExport = [...this.dataToExport, ...nodes]
          if (page.hasNextPage) {
            this.exportData(page.endCursor, typeExport)
          } else {
            const dataTable = this.dataToExport.map(item => ({
              [this.$t('User')]: item.username,
              [this.$t('name')]: `${item.firstName} ${item.lastName}`,
              [this.$t('Email')]: item.email,
              [this.$t('code.status')]: item.isActive ? this.$t('dataGeneric.active') : this.$t('dataGeneric.inactive'),
            }))
            const f = new Date()
            const m = f.getMonth() + 1
            const name = `${this.$t('fileNameUserRegistered') + f.getFullYear() + (m < 10 ? (`0${m}`) : `${m}`)}${f.getDate()}`

            // eslint-disable-next-line new-cap
            const xls = new xlsExport(dataTable, this.$t('Users'))
            if (typeExport === 'XLS') xls.exportToXLS(`${name}.xls`)
            else xls.exportToCSV(`${name}.csv`)

            this.exporting = !this.exporting
            this.isBusy = !this.isBusy
          }
        }).catch(err => {
          console.log(err)
          showToast(this.$t('subtitles.translationError'), 0, this)
        })
    },
    exportTable(data) {
      this.dataToExport = []
      this.exporting = !this.exporting
      this.isBusy = !this.isBusy
      this.exportData('', data)
    },
  },
}
</script>
